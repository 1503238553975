<template>
    <v-form v-if="user" class="grid grid-cols-1 md:grid-cols-2 gap-default" @submit.prevent="submitUserForm(userForm.user_id, userForm)">
        <v-form-group :permissions="fieldPermissions">
            <v-form-label>PayPal Email</v-form-label>
            <v-form-input v-model="userForm.paypal_email" type="text" name="paypal_email" :disabled="isLoading" />
        </v-form-group>

        <v-form-group :permissions="fieldPermissions">
            <v-form-label>PayPal Me Link</v-form-label>
            <v-form-input v-model="userForm.paypal_me" type="text" name="paypal_me" :disabled="isLoading" />
        </v-form-group>

        <v-form-group class="col-span-full flex w-full justify-end">
            <v-button class="w-full sm:w-auto" color="primary" text-alignment="center" :disabled="isLoading || !($me.hasPermission('users.update') || userForm.user_id == $me.getUser().user_id)">
                Save Changes
            </v-button>
        </v-form-group>
    </v-form>
</template>

<script>
import UserService from "@/services/modules/user-service";

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            userForm: {},
        };
    },
    computed: {
        fieldPermissions() {
            return (parseInt(this.userForm.user_id, 10) !== parseInt(this.$me.getUser().user_id, 10))
                ? ["users.update"]
                : [];
        },
    },
    watch: {
        user: {
            handler(value) {
                this.userForm = JSON.parse(JSON.stringify(value));
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        submitUserForm(userId, payload) {
            this.toggleLoading();

            UserService.updatePayoutInformation(userId, payload)
                .then((response) => {
                    this.$emit("user-updated", response);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },
    },
};
</script>
